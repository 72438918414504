<template>
  <div class="content">
    <app-cells position="between">
      <app-cells :indent="false">
        <h1 class="title title--theme">Договор (ID: {{ form.id }})</h1>
        <div class="status" v-if="statusObj">
          <div class="status__item" :class="`status__item--${statusObj.code}`">{{ statusObj.name }}</div>
        </div>
      </app-cells>
      <app-button
        v-if="userIsHead && (form.status === 1 || form.status === 3)"
        theme="error"
        size="small"
        @click="onShowModal"
      >
        Удалить
      </app-button>
    </app-cells>
    <div v-if="!userIsAdmin && form.status === 3 && form.comment_reject" class="notice notice--mb notice--red">
      <div class="notice__title">
        <img src="@/assets/img/info-icon-red.svg" alt="Info icon">
        <span>Комментарий отказа</span>
      </div>
      <p class="notice__text">
        {{ form.comment_reject }}
      </p>
    </div>
    <app-cells v-if="userIsAdmin || (userIsHead && form.status === 4)" position="start">
      <template v-if="userIsAdmin">
        <app-button
          size="link"
          :class="{'btn--loading': download_app.word}"
          @click="onDownloadApplication(false, 'docx', 'word', false)"
        >
          <img src="@/assets/img/file/import-icon.svg" alt="Скачать">
          <span>Скачать Word</span>
        </app-button>
        <app-button
          size="link"
          :class="{'btn--loading': download_app.word_seal}"
          @click="onDownloadApplication(false, 'docx', 'word_seal', true)"
        >
          <img src="@/assets/img/file/import-icon.svg" alt="Скачать">
          <span>Скачать Word с печатью</span>
        </app-button>
      </template>
      <template v-if="userIsAdmin || (userIsHead && form.status === 4)">
        <app-button
          size="link"
          :class="{'btn--loading': download_app.pdf}"
          @click="onDownloadApplication(true, 'pdf', 'pdf', false)"
        >
          <img src="@/assets/img/file/import-icon.svg" alt="Скачать">
          <span>Скачать PDF</span>
        </app-button>
        <app-button
          size="link"
          :class="{'btn--loading': download_app.pdf_seal}"
          @click="onDownloadApplication(true, 'pdf', 'pdf_seal', true)"
        >
          <img src="@/assets/img/file/import-icon.svg" alt="Скачать">
          <span>Скачать PDF с печатью</span>
        </app-button>
      </template>
    </app-cells>
    <form @submit.prevent="onCheckForm">
      <app-grid class="grid--indent">
        <template #item-1>
          <app-form-group label="Участник" required>
            <v-select
              v-model="form.participant"
              :options="participant_options"
              :filterable="true"
              @input="onSetParticipant"
              label="last_name"
              placeholder="Выберите участника"
              class="select "
              :class="{ 'select--error': $v.form.participant.$error }"
              :readonly="readonlyCondition"
            >
              <template #open-indicator>
                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path
                    d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                    fill="#fff"></path>
                </svg>
              </template>
              <template #option="option">
                <div class="select__item d-center">{{ option.last_name }} {{ option.first_name }} {{ option.patronymic ? option.patronymic : '' }}</div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">{{ option.last_name }} {{ option.first_name }} {{ option.patronymic ? option.patronymic : '' }}</div>
              </template>
              <template #no-options>Ничего не найдено</template>
            </v-select>
            <template #error>
              <div v-if="$v.form.participant.$dirty && !$v.form.participant.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group required label="Инициалы и фамилия" label-for="initials_surname">
            <app-input
              v-model="form.initials_surname"
              id="initials_surname"
              placeholder="Введите значение"
              :error="$v.form.initials_surname.$error"
              :readonly="readonlyCondition"
            />
            <template #error>
              <div v-if="$v.form.initials_surname.$dirty && !$v.form.initials_surname.required">Обязательное поле</div>
            </template>
          </app-form-group>
        </template>
        <template #item-2>
          <app-form-group required label="Дата договора" label-for="date">
            <app-input
              v-model="form.date"
              v-mask="'99.99.9999'"
              :error="$v.form.date.$error"
              placeholder="дд.мм.гггг"
              autocomplete="off"
              id="date"
              @paste.native.prevent
              :readonly="readonlyCondition"
            />
            <template #error>
              <div v-if="$v.form.date.$dirty && !$v.form.date.underscorePresent">Заполните поле полностью</div>
              <div v-if="$v.form.date.$dirty && !$v.form.date.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group required label="Тариф участия">
            <app-cells position="start" :indent="false">
              <app-radio
                v-for="cost in participant_cost_options"
                :key="cost.id"
                v-model.number="form.rate"
                :value="cost.id"
                :label="formatSum(cost.amount)"
                name="rate"
                :readonly="readonlyCondition"
              />
            </app-cells>
            <template #error>
              <div v-if="$v.form.rate.$dirty && !$v.form.rate.required">Обязательное поле</div>
            </template>
          </app-form-group>
        </template>
      </app-grid>
      <div v-if="userIsAdmin" class="color-panel color-panel--indent">
        <app-cells position="start">
          <h2 class="title">Управление</h2>
        </app-cells>
        <app-grid items="3" col="3">
          <template #item-1>
            <app-form-group label="Служебный комментарий">
              <app-textarea
                v-model="form.comment_staff"
                id="comment_staff"
                placeholder="Введите комментарий"
              />
            </app-form-group>
          </template>
          <template #item-2>
            <app-form-group label="Комментарий отказа">
              <app-textarea
                v-model="form.comment_reject"
                id="comment_reject"
                placeholder="Введите комментарий"
              />
            </app-form-group>
          </template>
          <template #item-3>
            <app-form-group label="Статус">
              <v-select
                v-model="form.status"
                :reduce="item => item.id"
                :options="status_options"
                :filterable="false"
                :clearable="false"
                :searchable="false"
                label="name"
                placeholder="Выберите значение"
                class="select"
              >
                <template #open-indicator>
                  <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                    <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
                  </svg>
                </template>
                <template #option="option">
                  <div class="select__item d-center">{{ option.name }}</div>
                </template>
                <template #selected-option="option">
                  <div class="selected d-center">{{ option.name }}</div>
                </template>
                <template #no-options>Ничего не найдено</template>
              </v-select>
            </app-form-group>
            <app-cells>
              <app-button ref="submit" :disabled="$v.form.$error">Сохранить изменения</app-button>
            </app-cells>
          </template>
        </app-grid>
      </div>
      <app-cells v-if="!userIsAdmin && (form.status === 1 || form.status === 3)">
        <app-button
          @click="onSendFormWithChangeStatus(form.status)"
          type="button"
          ref="submit"
          theme="transparent"
          :disabled="$v.form.$error"
        >
          Сохранить
        </app-button>
        <app-button
          @click="onSendFormWithChangeStatus(2)"
          type="button"
          ref="submitReview"
          :disabled="$v.form.$error"
        >
          Отправить на проверку
        </app-button>
      </app-cells>
    </form>
    <modal
      name="delete-modal"
      :width="750"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
    >
      <div class="modal">
        <div class="modal__title modal__title--center-black">Подтверждение действия</div>
        <p class="modal__text">
          Вы собираетесь удалить договор - ID: {{ form.id }}.
          Это действие невозможно будет отменить и данные будут удалены безвозвратно. Вы уверены, что хотите это сделать?
        </p>
        <app-cells position="center">
          <app-button size="small" @click="onDeleteNumber" ref="submitDelete">Да</app-button>
          <app-button size="small" @click="onHideModal">Нет</app-button>
        </app-cells>
      </div>
    </modal>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import {
  getMembersList,
  getParticipationCost,
  getContractIndividual,
  putContractIndividual,
  getStatus,
  deleteContractIndividual,
  getContractIndividualExport
} from '@/http'
import { dateFormatting } from '@/helpers'

const underscorePresent = value => {
  if (!value) return true
  else return !value.includes('_')
}

export default {
  name: 'ContractsIndividualEdit',
  data() {
    return {
      form: {},
      participant_options: [],
      participant_cost_options: [],
      status_options: [],
      status_to_set: null,
      download_app: {
        word: false,
        word_seal: false,
        pdf: false,
        pdf_seal: false,
      },
    }
  },
  validations: {
    form: {
      participant: { required },
      initials_surname: { required },
      rate: { required },
      date: { required, underscorePresent },
    }
  },
  computed: {
    userIsHead() {
      return this.$store.state.user_role === 'head'
    },
    userIsAdmin() {
      return this.$store.state.user_role === 'admin'
    },
    readonlyCondition() {
      return !this.userIsAdmin && (this.form.status === 2 || this.form.status === 4)
    },
    statusObj() {
      if (!this.status_options.length || !this.form.status) return
      return this.status_options.find(item => item.id === this.form.status)
    }
  },
  created() {
    getMembersList({page_size: 200})
      .then(response => {
        this.participant_options = response.data.results
        getContractIndividual(this.$route.params.id)
          .then(response => {
            this.form = {
              ...response.data,
              participant: this.participant_options.find(item => item.pk === response.data.participant),
              date: dateFormatting(response.data.date, 'iso-to-normal-small')
            }
          })
          .catch(() => {
            this.$router.push({ name: 'contracts-list' })
          })
      })
    getParticipationCost()
      .then(response => {
        this.participant_cost_options = response.data
      })
    getStatus()
      .then(response => {
        this.status_options = response.data
      })
  },
  methods: {
    onDownloadApplication(pdf, extension, download, seal) {
      this.download_app[download] = true
      getContractIndividualExport(this.$route.params.id, pdf, seal)
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `Договор с физическим лицом_${this.$route.params.id}.${extension}`)
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .finally(() => {
          this.download_app[download] = false
        })
    },
    onShowModal() {
      this.$modal.show('delete-modal')
    },
    onHideModal() {
      this.$modal.hide('delete-modal')
    },
    onDeleteNumber() {
      this.$refs.submitDelete.preload = true
      deleteContractIndividual(this.$route.params.id)
        .finally(() => {
          this.$refs.submitDelete.preload = false
        })
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Договор удален'
          })
          this.$router.push({ name: 'contracts-list' })
        })
    },
    onSendFormWithChangeStatus(status) {
      this.status_to_set = status
      this.onCheckForm()
    },
    onCheckForm() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        })
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      this.$refs.submit.preload = true
      if (this.$refs.submitReview) this.$refs.submitReview.preload = true
      const form = {...this.form}
      form.date = dateFormatting(form.date, 'normal-to-iso-small')
      form.participant = form.participant.pk
      if (this.status_to_set) form.status = this.status_to_set
      putContractIndividual(this.$route.params.id, form)
        .finally(() => {
          this.$refs.submit.preload = false
          if (this.$refs.submitReview) this.$refs.submitReview.preload = false
        })
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Договор изменен'
          })
          this.$router.push({ name: 'contracts-list' })
        })
    },
    formatSum(sum) {
      return new Intl.NumberFormat('ru-RU').format(sum)
    },
    onSetParticipant() {
      const nameLetter = this.form.participant.first_name.slice(0, 1).toUpperCase()
      if (this.form.participant.patronymic) {
        const patronymicLetter = this.form.participant.patronymic.slice(0, 1).toUpperCase()
        this.form.initials_surname = `${nameLetter}.${patronymicLetter}. ${this.form.participant.last_name}`
        return
      }
      this.form.initials_surname = `${nameLetter}. ${this.form.participant.last_name}`
    }
  }
}
</script>
